<template>
  <div id="Privat">
    <div class="card-header bg-brand px-3 py-4 rounded-t-lg">
      <p>
        <span class="brand-color-dark">Persönliche Informationen</span><br />
        Deine persönlichen Informationen werden nur mit anderen Nutzern geteilt, wenn es zwingend erforderlich ist. Es gibt kein öffentliches Nutzerprofil über Dich.
      </p>
    </div>
    <div class="card-content">

      <div class="grid grid-cols-2 gap-6">
                <div class="col-span-1">
                  <label for="firstname" class="block text-sm font-bold">Vorname</label>
                  <input disabled :value="user.first_name" type="text" name="firstname" id="firstname" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm  border-gray-300 rounded-md" />
                </div>

                <div class="col-span-1 ">
                  <label for="lastname" class="block text-sm font-bold">Nachname</label>
                  <input disabled :value="user.last_name" type="text" name="lastname" id="lastname" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm  border-gray-300 rounded-md" />
                </div>
      </div> 

      <p class="input-helper-text">
        Kontaktiere uns bitte, falls sich Deine Daten geändert haben.
      </p>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: "Privat",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>

<style scoped>
.circular--portrait {
  /* position: relative; */
  overflow: hidden;
  border-radius: 50%;
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
  width: 100px;
  height: 100px;
}

.circular--portrait img {
  width: 100%;
  height: auto;
}
</style>